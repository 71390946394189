import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData } from '@/mock/SiteData';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { TitleCopy } from '@/components/shared/title-copy';
import { ImageGallery } from '@/components/shared/image-gallery';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import ReactLogo from '@/assets/images/technologies/react.inline.svg';
import GreenSockLogo from '@/assets/images/technologies/greensock.webp';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy = CaseStudyData.find((m) => m.id === 'gamer-brain');

const GamerBrain = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const titleCopyThird = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const animatedTagCopyRef = useScrollAnimation();

  const lightSeparatorRef = useScrollAnimation();

  const darkSeparatorRef = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="flex flex-col gap-36 bg-white py-36">
          <TitleCopy
            ref={titleCopyFirst}
            title="The Client"
            description="HP (Hewlett-Packard) is a worldwide known company, mainly due to their great electronic products, that ranges from desktop pcs to printers and more."
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGalleryFirst}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[
              { src: '/images/case-studies/gamerbrain/mock-1.webp', alt: 'image1' },
              { src: '/images/case-studies/gamerbrain/mock-2.webp', alt: 'image2' },
            ]}
          />
          <TitleCopy
            ref={titleCopySecond}
            title="The Need"
            description={`This time, the request came from one of HPs business units called OMEN, they focus mainly on gaming pcs and peripherals. They had a realy ambitous idea for a publicitary campaign under the concept of “Gamer Brains”, wich consisted on a quiz with a series of questions that at the end determined, based on your response, what kind of gamer brain you have, It was a really fun a nice concept.`}
            className={ANIMATED_CLASSNAME}
          />
          <ImageGallery
            ref={imageGallerySecond}
            className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
            images={[{ src: '/images/case-studies/gamerbrain/scroll.gif', alt: 'gif1' }]}
          />
          <TitleCopy
            ref={titleCopyThird}
            title="The Solution"
            description={`So we got to work, for this project we decided on using React as the tool for the job, not only for our solid experience working with it, but also for the great DOM interaction it offers, since this was going to be a project that realied heavily on animations and playing around with the DOM elements.\n\nRegarding the animation side of things, we used GreenSock, mainly because we consider that when it comes to animating a website there really isn’t a better solution than what this library offers. Not just from the ease of use, that allows you to really let your imagination fly without the concern of being something unattainable, but also, from the performance side of things. It really is a great library and one we whole heartedly recommend to anyone doing a project that realies heavily on storytelling or animations.\n\nWith these tools at hand we managed to create something that not just us, but the client too, are really proud of.`}
            className={ANIMATED_CLASSNAME}
          />
          <Separator
            ref={lightSeparatorRef}
            className={cn('-mb-36 mx-auto max-w-7xl', ANIMATED_CLASSNAME)}
          />
        </div>
        <div className="animated-copy animate-transition flex flex-col gap-36 bg-loop-black py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              <ReactLogo key="vue" />,
              <img className="w-40" src={GreenSockLogo} key="greenSock" alt="greenSock" />,
            ]}
            description=""
            className={{
              section: cn(ANIMATED_CLASSNAME, 'lg:items-center'),
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              ref={animatedTagCopyRef}
              tag="the result"
              className={{
                section: cn(ANIMATED_CLASSNAME, 'mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: 'We delivered a website that, in addition to its <w>refinement<w> and <w>simplicity<w>, reinforces the values and concepts of the brand.',
                trigger: '.animated-copy',
                animationStart: 'top 30%',
                className: 'mx-auto',
              }}
            />
            <AnimatedTagCopy
              className={{ section: 'mx-auto mt-12 px-4 md:px-8 xl:px-0' }}
              animatedCopy={{
                id: 'animated-copy-2',
                text: 'Easy to access, <w>dynamic<w> and <w>agile<w>, a website that met the needs of the client and that its users will enjoy browsing. Go ahead! We invite you to discover a site with a <w>narrative<w> like no other.',
                trigger: '.animated-copy',
                revealDelay: 0.75,
                animationStart: 'top 30%',
                className: 'mx-auto',
              }}
            />
          </div>
          <Separator
            ref={darkSeparatorRef}
            className={cn('mx-auto max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default GamerBrain;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO
        title="Loop3 Studio - GamerBrain"
        description={`${caseStudy.hero.heroDescriptionText}`}
      />
    );
  }
};
